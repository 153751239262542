import { Box, Grid, Paper, Stack, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useState } from "react";
import { formatNumber } from "../util/format";
import InvestmentButtonTopup from "../investments/components/InvestmentButtonTopup";

const InfoBlock = ({ subTitle, title }) => (
    <Stack>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="caption">{subTitle}</Typography>
    </Stack>
)
const ExpandPanel = ({ backgroundColor, balances, children, color, dataArray, text }) => {

    const [showMore, setShowMore] = useState(false);

    const toggle = () => {
        setShowMore(!showMore);
    }
    
    return (
        <Grid item>
            <Paper elevation={3} sx={{
                backgroundColor,
                borderRadius: 3,
                padding: 2,
                margin: '8px'
            }}>
                <Stack direction={"row"} spacing={2}>
                    {children}
                    <Stack direction={"row"} spacing={2} sx={{ color: '#fff', justifyContent: 'space-between', width: '100%'}}>
                        <InfoBlock subTitle={`Monthly Income: £ ${formatNumber(balances.bottomLeft)}`} title={`Balance: £ ${formatNumber(balances.topLeft)}`} />
                        <InfoBlock
                            subTitle={(balances.loans >= balances.quantity && balances.cash > 0) ? <InvestmentButtonTopup cash={balances.loan - balances.cash} id={balances.id} /> : 'Cash balance'}
                            title={`£ ${formatNumber(balances.topRight)}`} />
                    </Stack>
                </Stack>
                {showMore && (
                    <>
                    {dataArray.map((item, index) => (
                        <Typography key={index} variant="body2" sx={{ color: '#fff', textAlign: 'right' }}>{item}</Typography>
                    ))}
                    </>
                )}
                <Box sx={{
                    color: '#fff',
                    cursor: 'pointer',
                    textAlign: 'center',
                    width: '100%'
                }} onClick={toggle}>{showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
            </Paper>
        </Grid>
    )
}

export const GreenExpandPanel = ({ balances, children, dataArray }) => {
    return (
        <ExpandPanel
            backgroundColor={'#1976d2'}
            balances={ balances }
            color={'#ffffff'}
            dataArray={dataArray}
            text={'Balance'}
        >{children}</ExpandPanel>
    )
}
