import { Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material"
import { formatNumber } from "../util/format"
import OffersAcceptButton from "./actions/OffersAcceptButton";

const backgroundColor = '#d5d5d5';
const color = '#111';

const filterSelected = (offers) => offers.filter(item => item.selected);
const getAverageRate = (offers) => {
    const filtered = filterSelected(offers);
    return filtered.reduce((total, item) => total + item.rate, 0) / filtered.length;
}
const getLongestTerm = (offers) => {
    const filtered = filterSelected(offers);
    return filtered.length > 0 ? filtered.sort((a, b) => b.term - a.term)[0].term : 0;
}

const getMonthlyInstallment = (offers) => filterSelected(offers).reduce((total, item) => total + item.installment, 0);
const getSelectedOffers = (offers) => filterSelected(offers).reduce((total, item) => total + item.amount, 0);

const textAvergageInterest = ({ offers }) => `Average Interest: ${formatNumber(getAverageRate(offers))} %`;
const textLongestTerm = ({ offers }) => `Longest Term: ${getLongestTerm(offers)} months`;
const textMonthlyInstallment = ({ offers }) => `Monthly Installment: £ ${formatNumber(getMonthlyInstallment(offers))}`
const textSelectedOffers = ({ offers }) => `Selected Offers: £ ${formatNumber(getSelectedOffers(offers))}`;

const InfoDisplay = ({ text }) => (
    <Typography align="right" border={1} borderColor="#aaa" borderRadius="4px" sx={{
        padding: '20px'
    }}>{ text }</Typography>

)

export const MortgageOffersOverview = ({ quote }) => {

    return (
        <Card raised sx={{
            backgroundColor,
            borderRadius: '12px',
            color,
            margin: '8px'
        }}>
            <CardHeader
                title={`Offers: £ ${formatNumber(quote.totalOffer)}`}
                sx={{
                    textAlign: 'center'
                }}
            />
            <CardContent>
                <Stack spacing={2}>
                    <InfoDisplay text={textSelectedOffers({ offers: quote.offers })} />
                    <InfoDisplay text={textMonthlyInstallment({ offers: quote.offers })} />
                    <InfoDisplay text={textLongestTerm({ offers: quote.offers })} />
                    <InfoDisplay text={textAvergageInterest({ offers: quote.offers })} />
                </Stack>
            </CardContent>
            <CardActions sx={{
                    justifyContent: 'center'
            }}>
                <OffersAcceptButton />
            </CardActions>
        </Card>
    )
}
