import { ADD_LOAN } from "../actionTypes";

const initialState = {
    allIds: [],
    byIds: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case ADD_LOAN: {
            const { id, content } = action.payload;
            return {
                ...state,
                allIds: [ ...state.allIds, id],
                byIds: {
                    ...state.byIds,
                    [id]: {content, completed: false}
                }
            };
        }
        default:
            return state;
    }
}
