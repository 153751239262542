import { ADD, ADD_LOAN, ADD_TODO, CANCEL, SET_FILTER, TOGGLE, TOGGLE_TODO } from "../../redux/actionTypes";

import { MORTGAGE_OFFERS, MORTGAGE_OFFER_SELECT_TOGGLE, MORTGAGE_UPDATE_AMOUNT } from './mortgageActionTypes';

let nextLoanId = 0;
let nextTodoId = 0;

export const addLoan = content => ({
    type: ADD_LOAN,
    payload: {
        id: ++nextLoanId,
        content
    }
});

export const addMortgage = content => ({
    type: ADD,
    payload: {
        content
    }
});

export const cancelMortgageQuote = () => {
    return ({
    type: CANCEL
})};

export const addTodo = content => ({
    type: ADD_TODO,
    payload: {
        id: ++nextTodoId,
        content
    }
});

export const setFilter = filter => ({
    type: SET_FILTER,
    payload: { filter }
});

export const toggleMortgageForm = () => ({ type: TOGGLE });

export const toggleMortgageOfferSelect = id => {
    return {
        type: MORTGAGE_OFFER_SELECT_TOGGLE,
        payload: { id }
    }
}
export const toggleTodo = id => ({
    type: TOGGLE_TODO,
    payload: { id }
});

export const updateMortgageAmount = value => {
    return ({
    type: MORTGAGE_UPDATE_AMOUNT,
    payload: { value }
})};

export const updateMortgageOffers = value => {
    return {
        type: MORTGAGE_OFFERS,
        payload: value
    };
}
