
import SavingsIcon from '@mui/icons-material/Savings';
import { GreenExpandPanel } from "../dashboard/ExpandPanel";

const InvestmentIcon = ({ color }) => (
    <SavingsIcon
        fontSize="large"
        sx={{
            backgroundColor: '#314c36',
            borderRadius: '8px',
            color: '#fff',
            margin: '6px',
            padding: '6px'
        }}
    />
);

export const InvestmentPod = ({data, title}) => {
   
    const balances = {
        bottomLeft: data.installment,
        topLeft: data.loans.reduce((total, item) => total + item.balance, 0),
        topRight: data.cash,
        loans: data.loans.length,
        quantity: data.quantity,
        cash: data.cash,
        loan: data.loan,
        id: data.id
    }

    const dataArray = [
        `Loans: ${data.loans.length}`,
        `Interest Rate: ${Number(data.rate).toFixed(2)} %`,
        `Term length: ${data.term} months`
    ];

    return (
        <>
            <GreenExpandPanel balances={balances} dataArray={dataArray}><InvestmentIcon /></GreenExpandPanel>
        </>
    )
}
