import { Card, CardContent, Grid, Typography } from "@mui/material"
import { InvestmentBalancePanel } from "../investments/InvestmentBalancePanel"
import InvestmentFormCreate from "../investments/InvestmentFormCreate";
import MortgageChartPrinciplePayment from "../mortgages/components/MortgageChartPrinciplePayment";
import MortgageChartRateBreakdown from "../mortgages/components/MortgageChartRateBreakdown";
import MortgageChartTermBreakdown from "../mortgages/components/MortgageChartTermBreakdown";
import { MortgageBalancePanel } from "../mortgages/MortgageBalancePanel"
import MortgageFormInput from "../mortgages/MortgageFormInput";
import MortgageOffers from "../mortgages/MortgageOffers";
import { MortgageOffersOverview } from "../mortgages/MortgageOffersOverview";

const LargeGrid = ({ children }) => (
    <Grid item xs={12}>
        { children }
    </Grid>
);

const MiniGrid = ({ children }) => (
    <Grid item lg={4} xs={12}>
        { children }
    </Grid>
);

const SmallGrid = ({ children }) => (
    <Grid item lg={6} xs={12}>
        { children }
    </Grid>
);

const introText = [
    'We are a new peer-to-peer lending network that focus on the residential re-mortgage market. A peer-to-peer lending network is where a borrower will borrow money directly from a private lender, instead of going to a big financial institution like a bank. We provide the lender with appropriate information like credit score and loan to value ratios, and they then decide how much money they would lend at what rate and term. The borrower can then either accept or reject the offer. These loans are secured against the property and will be regulated in the same way as a mortgage through a traditional financial institution.',
    'Home owners that get a mortgage on our network have a list of all the smaller loans, and they can see the impact when settling these smaller loans early. We will actively encourage home owners to settle their mortgage early, helping them on the road to financial freedom, and hopefully turn them into investors on our platform.',
    'Investors will be able to earn more interest on our platform as they can in the banking sector, at a low risk. Investors can build a portfolio of different loan options on the platform to spread their risk, and to lower risk even more we have a limit in the size of loans to borrowers.',
    'These are long-term loans that is set to run between 10 and 30 years, except when the loan is settled early by the borrower. However, circumstances do change and if a lender do need access to their money, we will have a marketplace where investors can trade debt. Thus way lenders that do need access to their money can sell the debt on the marketplace and withdraw it from the platform.',
    'We are still actively developing the platform, but you can now play with a simulation of how it will work. Add a mortgage or investment option below and play around. This is not the final product, just a simulation of how we see the platform and business plan will work.'
]


const IntroPanel = () => (
    <Card raised sx={{
        backgroundColor: '#837699',
        borderRadius: '12px',
        color: '#fff'
    }}>
        <CardContent>
            {introText.map(text => <Typography align="justify">{text}</Typography>)}
        </CardContent>
    </Card>
)

const MortgageApplication = ({ mortgages }) => (
    <>
        {mortgages.displayForm && <SmallGrid><MortgageFormInput /></SmallGrid>}
        {mortgages.quote.amount > 0 && <SmallGrid><MortgageOffersOverview quote={mortgages.quote} /></SmallGrid>}
        {mortgages.quote.amount > 0 && <LargeGrid><MortgageOffers /></LargeGrid>}
    </>
);

const MortgageOverview = ({ mortgages }) => (
    <>
        <MiniGrid><MortgageChartPrinciplePayment data={mortgages.loans} /></MiniGrid>
        <MiniGrid><MortgageChartRateBreakdown data={mortgages.loans} /></MiniGrid>
        <MiniGrid><MortgageChartTermBreakdown data={mortgages.loans} /></MiniGrid>
    </>
)

export const MainPanel = ({ investments, mortgages }) => {

    return (
        <Grid container spacing={2}>
            <SmallGrid><MortgageBalancePanel mortgages={mortgages} /></SmallGrid>
            <SmallGrid><InvestmentBalancePanel investments={investments} /></SmallGrid>
            {(Number(investments.forms.openCreate) + Number(mortgages.displayForm)) === 0 && mortgages.balance === 0 &&
            <LargeGrid>
                <IntroPanel />
            </LargeGrid>}
            {investments.forms.openCreate && <LargeGrid><InvestmentFormCreate /></LargeGrid>}
            <MortgageApplication mortgages={mortgages} />
            {mortgages.loans.length > 0 && <MortgageOverview mortgages={mortgages} />}
        </Grid>
    )
}
