import { combineReducers } from "@reduxjs/toolkit";

import { investments } from '../../investments/reducers/investments';
import { mortgages } from '../../mortgages/reducers/mortgages';

import loans from './loans';
import todos from './todos';
import visibilityFilter from './visibilityFilter';

export default combineReducers({ investments, loans, mortgages, todos, visibilityFilter});
