import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import TableHeading from "../components/TableHeading";
import { formatNumber } from "../util/format";

import MortgageButtonEarlySettlement from "./components/MortgageButtonEarlySettlement";

const backgroundColor = '#f5f5f5';
const color = '#111';

const sortingInit = {
    installment: '',
    offer: '',
    rate: '',
    settlement: '',
    term: ''
};

const InfoDisplay = ({ text }) => (
    <Typography align="right" border={1} borderColor="#aaa" borderRadius="4px" sx={{
        padding: '20px'
    }}>{ text }</Typography>
)

const Detail = ({ loan, click }) => {
    return (
        <Card raised sx={{
            backgroundColor: '#d5d5d5',
            borderRadius: '12px',
            color: '#111',
            margin: '8px'
        }}>
            <CardHeader
                title="Loan Details"
                sx={{
                    textAlign: 'center'
                }}
            />
            <CardContent>
                <Stack spacing={2}>
                    <InfoDisplay text={`Principle Amount: £ ${formatNumber(loan.amount)}`} />
                    <InfoDisplay text={`Monthly Installment: £ ${formatNumber(loan.installment)}`} />
                    <InfoDisplay text={`Interest Rate: ${formatNumber(loan.rate)} %`} />
                    <InfoDisplay text={`Payments left: ${formatNumber(loan.detail.length)}`} />
                </Stack>
            </CardContent>
        </Card>
    )
};

const Settlement = ({ loan, click }) => {
    return (
        <Card raised sx={{
            backgroundColor: '#d5d5d5',
            borderRadius: '12px',
            color: '#111',
            margin: '8px'
        }}>
            <CardHeader
                title="Loan Settlement"
                sx={{
                    textAlign: 'center'
                }}
            />
            <CardContent>
                <Stack spacing={2}>
                    <InfoDisplay text={`Loan Total Interest: £ ${formatNumber(loan.totalInterest)}`} />
                    <InfoDisplay text={`Early Settlement Amount: £ ${formatNumber(loan.detail[0].early)}`} />
                    <InfoDisplay text={`Early Settlement Saving: £ ${formatNumber(loan.amount + loan.totalInterest - loan.detail[0].early)}`} />
                </Stack>
            </CardContent>
            <CardActions sx={{
                justifyContent: 'center'
            }}>
                <MortgageButtonEarlySettlement id={loan.id} click={click} />
            </CardActions>
        </Card>
    )
}

const Row = ({ loan }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow onClick={() => setOpen(!open)} sx={{
                backgroundColor: '#dad6e1'
            }}>
                <TableCell>
                    <IconButton
                        size="small"
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">£ {formatNumber(loan.amount)}</TableCell>
                <TableCell align="center">{loan.rate} %</TableCell>
                <TableCell align="center">{loan.term}</TableCell>
                <TableCell align="center">£ {Number(formatNumber(loan.installment)).toFixed(2)}</TableCell>
                <TableCell align="center">£ {formatNumber(loan.detail[0].early)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Grid container spacing={2}>
                            <Grid item lg={6} xs={12}>
                                <Detail loan={loan} click={() => setOpen(false)} />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Settlement loan={loan} click={() => setOpen(false)} />
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export const MortgageDetails = ({ loans }) => {

    const [ sorting, setSorting ] = useState(sortingInit);

    const sortInstallment = () => {
        const order = sorting.installment === 'desc' ? 'asc' : 'desc';
        loans.sort((a, b) => order === 'desc' ? a.installment - b.installment : b.installment - a.installment);
        setSorting({ ...sortingInit, installment: order});
    }

    const sortOffer = () => {
        const order = sorting.offer === 'desc' ? 'asc' : 'desc';
        loans.sort((a, b) => order === 'desc' ? a.amount - b.amount : b.amount - a.amount);
        setSorting({ ...sortingInit, offer: order});
    }

    const sortRate = () => {
        const order = sorting.rate === 'desc' ? 'asc' : 'desc';
        loans.sort((a, b) => order === 'desc' ? a.rate - b.rate : b.rate - a.rate);
        setSorting({ ...sortingInit, rate: order});
    }

    const sortSettlement = () => {
        const order = sorting.settlement === 'desc' ? 'asc' : 'desc';
        loans.sort((a, b) => order === 'desc' ? a.detail[0].early - b.detail[0].early : b.detail[0].early - a.detail[0].early);
        setSorting({ ...sortingInit, settlement: order });
    }

    const sortTerm = () => {
        const order = sorting.term === 'desc' ? 'asc' : 'desc';
        loans.sort((a, b) => order === 'desc' ? a.term - b.term : b.term - a.term);
        setSorting({ ...sortingInit, term: order });
    }

    return (
        <Card raised sx={{
            backgroundColor,
            borderRadius: '12px',
            color,
            margin: '8px'
        }}>
            <CardHeader
                title="Mortgage Details"
                sx={{
                    textAlign: 'center'
                }}
            />
            <CardContent>
            <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{
                                backgroundColor: '#5c3d93'
                            }}>
                                <TableCell/>
                                <TableCell align="center" onClick={sortOffer} >
                                    <TableHeading className="centerAlign" heading="Amount" sort={sorting.offer} />
                                </TableCell>
                                <TableCell align="center" onClick={sortRate}>
                                    <TableHeading className="centerAlign" heading="Rate" sort={sorting.rate} />
                                </TableCell>
                                <TableCell align="center" onClick={sortTerm}>
                                    <TableHeading className="centerAlign" heading="Term" sort={sorting.term} />
                                </TableCell>
                                <TableCell align="center" onClick={sortInstallment}>
                                    <TableHeading className="centerAlign" heading="Installment" sort={sorting.installment} />
                                </TableCell>
                                <TableCell align="center" onClick={sortSettlement}>
                                    <TableHeading className="centerAlign" heading="Settlement" sort={sorting.settlement} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loans.map(loan => (
                                <Row key={loan.id} loan={loan} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}
