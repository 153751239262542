import { Stack, Typography } from "@mui/material"

const ContentPanel = ({ align, color, label, value }) => (
    <Stack sx={{
        textAlign: align
    }}>
        <Typography variant="caption" sx={{ color }}>{label}</Typography>
        <Typography variant="h5" sx={{ color }}>{value}</Typography>
    </Stack>
);

export const MainRow = ({ color, leftPanel, rightPanel }) => {

    return (
        <Stack direction={'row'} sx={{ justifyContent: 'space-around'}}>
           <ContentPanel align={'left'} color={color} label={leftPanel && leftPanel.label ? leftPanel.label : ''} value={leftPanel && leftPanel.value ? leftPanel.value : ''} />
           <ContentPanel align={'right'} color={color} label={rightPanel && rightPanel.label ? rightPanel.label : ''} value={rightPanel && rightPanel.value ? rightPanel.value : '0'} />
        </Stack>
    )
}
