import { Button, Card, CardActions, CardContent, CardHeader, Slider, Stack, TextField, Typography } from "@mui/material";
import axios from 'axios';
import { numberFormat } from "highcharts";
import { Component } from "react";
import { connect } from "react-redux";
import { inputCurrencyFormatter, inputCurrencyRemover } from "../util/format";
import { MORTGAGE_FORM_INPUT_CANCEL, MORTGAGE_OFFERS } from "./actions/mortgageActionTypes";

const backgroundColor = '#d5d5d5';
const color = '#111';

const StandardButton = ({ label, click }) => (
    <Button sx={{
        borderColor: color,
        color
    }}
        onClick={() => click()}
    >{ label }</Button>
);

const StandardInput = ({ error, helperText, label, value, change }) => (
    <TextField
        InputProps={{
            inputProps: {style: {
                textAlign: "right"
            }}
        }}
        error={error}
        helperText={helperText}
        label={label}
        variant="outlined"
        value={value === '' ? '' : inputCurrencyFormatter(value)}
        onChange={(event) => change(event)}
    />
);

class MortgageFormInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mortgageValue: '',
            mortgageValueError: false,
            propertyValue: '',
            propertyValueError: false
        }
    }

    getOffers = async(amount) => {
        const response = await axios.post('https://xuqpbtmdui.execute-api.us-east-1.amazonaws.com/mortgages/offers', {
            amount
        });
        const { offers, totalOffer } = response.data;
        offers.forEach((offer, index) => {
            offer.id = index + 1;
            offer.selected = false;
        });
        this.props.offers({ amount, offers, totalOffer });
    } 

    handleCancel = () => {
        this.props.cancel();
    }

    handleClick = () => {
        this.getOffers(this.state.mortgageValue);
    }

    updateMortgageValue = (event) => {
        const value = inputCurrencyRemover(event.target.value);
        if (value < 0 || value > 350000) {
            this.setState({ mortgageValueError: true });
        } else {
            this.setState({ mortgageValue: value, mortgageValueError: false });
        }
    }

    updatePropertyValue = (event) => {
        const value = inputCurrencyRemover(event.target.value);
        if (value < 0 || value > 10000000) {
            this.setState({ propertyValueError: true })
        } else {
            this.setState({ propertyValue: value, propertyValueError: false });
        }
    }

    render() {
        return (
            <Card raised sx={{
                backgroundColor,
                borderRadius: '12px',
                color,
                margin: '8px'
            }}>
                <CardHeader title="Mortgage Input" sx={{
                    textAlign: 'center'
                }} />
                <CardContent>
                    <Stack spacing={2}>
                        <StandardInput
                            error={this.state.propertyValueError}
                            helperText={this.state.propertyValueError ? 'Your property value cannot be negative and are capped at £10 million' : ''}
                            label='Proprty value'
                            value={this.state.propertyValue}
                            change={(event) => this.updatePropertyValue(event)}
                        />
                        <StandardInput
                            error={this.state.mortgageValueError}
                            helperText={this.state.mortgageValueError ? 'You mortgage value cannot be negative or more than £350,000' : ''}
                            label='Mortgage value'
                            value={this.state.mortgageValue}
                            change={(event) => this.updateMortgageValue(event)}
                        />
                        <Typography align="right" border={1} borderColor="#aaa" borderRadius="4px" sx={{
                            padding: '20px'
                        }}>Loan to value ratio: {numberFormat((this.state.mortgageValue / this.state.propertyValue) * 100)} %</Typography>
                        <Stack border={1} borderColor="#aaa" borderRadius="4px" sx={{
                            marginTop: '24px',
                            padding: '20px'
                        }}>
                            <Slider defaultValue={660} max={850} min={500} marks={[{value: 540, label: 'Very Poor'}, {value: 600, label: 'Poor'}, {value: 640, label: 'Fair'}, {value: 665, label: 'Good'}, {value: 730, label: 'Very Good'}, {value: 800, label: 'Excellent'}]} onChange={this.handleScoreChange} />
                            <Typography align="right">Your Credit Rating</Typography>
                        </Stack>
                    </Stack>
                </CardContent>
                <CardActions sx={{
                    justifyContent: 'center'
                }}>
                    <StandardButton label='Get Offers' click={this.handleClick} />
                    <StandardButton label='Cancel' click={this.handleCancel} />
                </CardActions>
            </Card>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        cancel: () => dispatch({ type: MORTGAGE_FORM_INPUT_CANCEL}),
        offers: (value) => dispatch({ type: MORTGAGE_OFFERS, payload: value })
    }
}

export default connect(null, mapDispatchToProps)(MortgageFormInput);
