import { Button, Stack, Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { connect } from 'react-redux';
import { TOP_BANNER_LEAP, TOP_BANNER_STEP } from './actions/TopBannerActionTypes';

import './TopBanner.styles.css';

const  TopBanner = ({ investments, leap, mortgages, showLeap, step }) => {

    return (
        <AppBar className='appToolbar' position='static' sx={{ marginBottom: 4 }}>
            <Toolbar>
                <Typography variant='h6' component='div' sx={{ flexGrow: 1}}>p2p-loans</Typography>
                {(investments.balance > 0 || mortgages.balance > 0) && (
                    <>
                    {showLeap
                        ? (<Stack direction={'row'} spacing={2}>
                                <Typography sx={{ color: 'white', margin: 'auto' }}>Click on the 'Leap' button to simulate the data the next month</Typography>
                                <Button color='inherit' onClick={leap}>Leap</Button>
                            </Stack>
                        )
                        : (<Stack direction={'row'} spacing={2}>
                                <Typography sx={{ color: 'white', margin: 'auto' }}>Click on the 'Step' button to simulate your loan offers being accpted</Typography>
                                <Button color='inherit' onClick={step}>Step</Button>
                            </Stack>
                        )
                    }
                    </>
                    
                )}
            </Toolbar>
        </AppBar>
    );    
}

const mapStateToProps = state => {
    const { investments, mortgages } = state;
    let showLeap = true;
    investments.pods.forEach(item => {
        if (item.loans.length < item.quantity) {
            showLeap = false;
        }
    });
    return {
        investments, mortgages, showLeap
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        leap: () => dispatch({ type: TOP_BANNER_LEAP }),
        step: () => dispatch({ type: TOP_BANNER_STEP })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBanner);
