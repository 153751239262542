import { TOP_BANNER_LEAP, TOP_BANNER_STEP } from "../../topbanner/actions/TopBannerActionTypes";
import { INVESTMENT_ADD, INVESTMENT_FORM_CLOSE, INVESTMENT_FORM_OPEN, INVESTMENT_TOPUP } from "../actions/investmentActionTypes";

const initialState = {
    balance: 0,
    forms: {
        openCreate: false
    },
    pods: []
}

const roundNumber = value => Math.round(value * 100) / 100;

const calculateLoan = ({ loan, rate, term, settlement }) => {
    const { interest, principle } = calculatePayment({ amount: loan, rate, term });
    const early = loan + (loan * (settlement / 100));
    return {loan, balance: loan, interest, principle, early, payments: term}
}

const calculatePayment = ({ amount, rate, term }) => {
    const monthlyRate = rate / 12 / 100;
    const factor = Math.pow(1 + monthlyRate, term);
    const payment = (amount * factor * monthlyRate) / (factor - 1)
    const interest = roundNumber(amount * monthlyRate);
    return {
        interest, principle: roundNumber(payment - interest)
    }
}

const leap = (state) => {
    state.balance = 0;
    state.pods.forEach(pod => {
        pod.loans = pod.loans.filter(loan => loan.payments > 0);
        pod.loans.forEach(loan => {
            loan.balance -= loan.principle;
            if (loan.balance < 0) {
                loan.balance = 0;
            }
            loan.payments--;
            pod.cash += (loan.principle + loan.interest);
            const { interest, principle } = calculatePayment({amount: loan.balance, rate: pod.rate, term: loan.payments});
            loan.interest = interest;
            loan.principle = principle ? principle : 0;
        });
        if (pod.cash / pod.loan > 1) {
            const { loan, rate, term, settlement } = pod;
            pod.loans.push(calculateLoan({ loan, rate, term, settlement }));
            pod.cash -= pod.loan;
        }
        state.balance += pod.loans.reduce((total, item) => total + item.balance, 0);
        pod.installment = pod.loans.reduce((total, item) => total + item.interest + item.principle, 0);
    });
    return {
        ...state
    }
}

const step = (state) => {
    state.pods.filter(item => item.loans.length < item.quantity).map(item => {
        const { loan, rate, settlement, term } = item;
        item.cash -= loan;
        const id = item.loans.length > 0 ? item.loans[item.loans.length - 1].id + 1 : 1;
        item.loans.push({ id, ...calculateLoan({ loan, rate, term, settlement })});
        return {
            ...item
        }
    });

    return {
        ...state
    };
}

export const investments = (state = initialState, action) => {
    switch(action.type) {
        case INVESTMENT_ADD: {
            state.forms.openCreate = false;
            const {loan, quantity} = action.payload.data;
            const id = state.pods.length > 0 ? state.pods[state.pods.length - 1].id + 1 : 1;
            state.pods.push({ id, ...action.payload.data, cash: loan * quantity, loans: [] });
            const balance = state.pods.reduce((total, item) => total + (item.loan * item.quantity), 0);
            return {
                ...state, balance
            }
        }
        case INVESTMENT_FORM_CLOSE: {
            return {
                ...state,
                forms: {
                    openCreate: false
                }
            }
        }
        case INVESTMENT_FORM_OPEN: {
            return {
                ...state,
                forms: {
                    openCreate: true
                }
            }
        }
        case INVESTMENT_TOPUP: {
            const {id} = action.payload;
            const pod = state.pods.find(pod => pod.id === id);
            const { loan, rate, settlement, term } = pod;
            const loanId = pod.loans.length > 0 ? pod.loans[pod.loans.length - 1].id + 1 : 1;
            pod.loans.push({ id: loanId, ...calculateLoan({ loan, rate, term, settlement})});
            pod.cash = 0;
            pod.balance = pod.loans.reduce((total, item) => total + item.balance, 0);
            state.balance = state.pods.reduce((total, item) => total + item.balance, 0);
            return {
                ...state
            }
        }
        case TOP_BANNER_LEAP: {
            return {
                ...leap(state)
            }
        }
        case TOP_BANNER_STEP: {
            return {
                ...step(state)
            }
        }
        default:
            return state;
    }
}
