import { Typography } from "@mui/material"
import { makeStyles } from '@mui/styles';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

const useStyles = makeStyles({
    heading: {
        color: '#fff',
        cursor: 'pointer'
    },
    headingRow: {
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        color: '#fff'
    }
});

const TableHeading = ({ heading, sort }) => {
    const classes = useStyles();
    return (
        <div className={classes.headingRow}>
            <Typography className={classes.heading}>{heading}</Typography>
            {sort === 'asc' && <NorthIcon className={classes.icon} fontSize="small" />}
            {sort === 'desc' && <SouthIcon className={classes.icon} fontSize="small" />}
        </div>
    )
}

export default TableHeading;
