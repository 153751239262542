import React, { Component } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { connect } from "react-redux";

import { MORTGAGE_OFFERS_ACCEPT, MORTGAGE_OFFERS_UPDATE, MORTGAGE_OFFER_SELECT_TOGGLE } from "./actions/mortgageActionTypes";
import TableHeading from "../components/TableHeading";
import { formatNumber } from "../util/format";
import { BlackButton } from '../components/DefaultButton';

import './MortgageOffers.styles.css';
import { sortArray } from "../util/helpers";

const backgroundColor = '#e5e5e5';
const color = '#111';

const useStyles = makeStyles(() => ({
    row: {
      marginTop: "16px",
      padding: "16px",
    },
    selected: {
      backgroundColor: "#b2a7c4",
      cursor: "pointer",
    },
    unselected: {
      backgroundColor: "white",
      cursor: "pointer",
    },
  }));

class MortgageOffers extends Component {

    constructor(props) {
        super(props);
        const sorting = props.sorting ? props.sorting : {
            installment: '',
            offer: '',
            rate: '',
            term: ''
        };
        this.state = {
            offers: props.offers, open: false, sorting
        }
    }

    classes = () => useStyles();

    acceptOffers = () => {
        const loans = this.props.offers.filter(offer => offer.selected).map(offer => ({ ...offer, selected: false}));
        this.props.accept(loans);
    }
    bulkSelect = () => {
        let { amount, offers, selectedOffers } = this.props;
        offers.forEach(offer => {
            if (selectedOffers < amount && !offer.selected) {
                selectedOffers += offer.amount;
                offer.selected = true;
            }
        });
        this.setState({offers});
        this.props.update(offers);
    }

    clear = () => {
        const offers = JSON.parse(JSON.stringify(this.state.offers));
        offers.forEach(offer => (offer.selected = false));
        this.setState({offers});
        this.props.update(offers);
    }

    handleClick = (id) => {
        const offers = JSON.parse(JSON.stringify(this.state.offers));
        const selected = offers.find(offer => offer.id === id).selected;
        const { amount, selectedOffers } = this.props;
        if (!selected && selectedOffers > amount) {
            this.setState({ open: true });
        } else {
            offers.find(offer => offer.id === id).selected = !selected;
            this.setState({ offers });
            this.props.update(offers);    
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    sortInstallment = () => {
        const order = this.state.sorting.installment === 'desc' ? 'asc' : 'desc';
        const offers = sortArray({
            array: this.state.offers,
            key: 'installment',
            order: order
        });
        this.setState({ offers, sorting: {
            installment: order,
            offer: '',
            rate: '',
            term: ''
        } });
        this.props.update(offers);
    }

    sortOffer = () => {
        const order = this.state.sorting.offer === 'desc' ? 'asc' : 'desc';
        const offers = sortArray({
            array: this.state.offers,
            key: 'amount',
            order: order
        });
        this.setState({ offers, sorting: {
            installment: '',
            offer: order,
            rate: '',
            term: ''
        } });
        this.props.update(offers);
    }
    
    sortRate = () => {
        const order = this.state.sorting.rate === 'desc' ? 'asc' : 'desc';
        const offers = sortArray({
            array: this.state.offers,
            key: 'rate',
            order: order
        });
        this.setState({ offers, sorting: {
            installment: '',
            offer: '',
            rate: order,
            term: ''
        } });
        this.props.update(offers);
    }

    sortTerm = () => {
        const order = this.state.sorting.term === 'desc' ? 'asc' : 'desc';
        const offers = sortArray({
            array: this.state.offers,
            key: 'term',
            order: order
        });
        this.setState({ offers, sorting: {
            installment: '',
            offer: '',
            rate: '',
            term: order
        } });
        this.props.update(offers);
    }

    render() {
        return (
            <>
                <Card raised sx={{
                    backgroundColor,
                    borderRadius: '12px',
                    color,
                    margin: '8px'
                }}>
                    <CardHeader
                        title="Available Offers"
                        sx={{
                            textAlign: 'center'
                        }}
                    />
                    <CardActions sx={{
                        justifyContent: 'right'
                    }}>
                        <BlackButton click={this.bulkSelect}>Quick Select</BlackButton>
                        <BlackButton click={this.clear}>Reset Selection</BlackButton>
                    </CardActions>
                    <CardContent>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                <TableCell align="center" onClick={this.sortOffer} >
                                    <TableHeading className="centerAlign" heading="Offer" sort={this.state.sorting.offer} />
                                </TableCell>
                                <TableCell align="center" onClick={this.sortRate}>
                                    <TableHeading className="centerAlign" heading="Rate" sort={this.state.sorting.rate} />
                                </TableCell>
                                <TableCell align="center" onClick={this.sortTerm}>
                                    <TableHeading className="centerAlign" heading="Term" sort={this.state.sorting.term} />
                                </TableCell>
                                <TableCell align="center" onClick={this.sortInstallment}>
                                    <TableHeading className="centerAlign" heading="Installment" sort={this.state.sorting.installment} />
                                </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {this.state.offers.map(offer => (
                                <TableRow className={offer.selected ? 'selected' : 'unselected'} key={offer.id} onClick={() => this.handleClick(offer.id)}>
                                    <TableCell align="center">£ {formatNumber(offer.amount)}</TableCell>
                                    <TableCell align="center">{offer.rate} %</TableCell>
                                    <TableCell align="center">{offer.term}</TableCell>
                                    <TableCell align="center">£ {Number(formatNumber(offer.installment)).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                    <CardActions sx={{
                        justifyContent: 'right'
                    }}>
                        <BlackButton click={this.bulkSelect}>Quick Select</BlackButton>
                        <BlackButton click={this.clear}>Reset Selection</BlackButton>
                    </CardActions>
                </Card>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <DialogContent>
                        <DialogContentText>You already accepted more offer than your specified amount, please unselect some offers before selecting more.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            autoFocus
                        >Close</Button>
                    </DialogActions>
                </Dialog> 
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { amount, offers, selectedOffers, sorting } = state.mortgages.quote;
    return { amount, offers, selectedOffers, sorting };
};

const mapDispatchToProps = (dispatch) => {
    return {
        accept: (loans) => dispatch({ type: MORTGAGE_OFFERS_ACCEPT, payload: {loans}}),
        toggle: (id) => dispatch({ type: MORTGAGE_OFFER_SELECT_TOGGLE, payload: {id}}),
        update: (offers) => dispatch({ type: MORTGAGE_OFFERS_UPDATE, payload: { offers }})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MortgageOffers);
