import { Button } from "@mui/material";

const DefaultButton = ({ children, click, color }) => (
    <Button
        sx={{
            color
        }}
        onClick={click}
    >{ children }</Button>
)

export const BlackButton = ({ children, click }) => (
    <DefaultButton click={click} color='#111'>{ children }</DefaultButton>
)
