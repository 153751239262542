import { LargeGreenPanel, LargePanelLayoutDetail, LargePanelLayoutEmpty } from "../dashboard/LargePanel"
import InvestmentButtonCreateOpen from "./components/InvestmentButtonCreateOpen"

import MovingIcon from '@mui/icons-material/Moving';
import { numberFormat } from "highcharts";

const InvestmentIcon = ({ color }) => (
    <MovingIcon
        fontSize="large"
        sx={{
            backgroundColor: '#314c36',
            borderRadius: '8px',
            color,
            margin: '6px',
            padding: '6px'
        }}
    />
);

export const InvestmentBalancePanel = ({ investments }) => {
    const color = '#c2dbc7';
    const subColor = '#5e6760';

    const leftPanel = {
        label: 'Monthly Income',
        value: `£ ${numberFormat(investments.pods.reduce((total, pod) => total + pod.installment, 0))}`
    }

    const rightPanel = {
        label: 'Loans',
        value: investments.pods.reduce((total, pod) => total + pod.loans.length, 0)
    }
    return (
        <LargeGreenPanel>
            {investments.balance === 0 && (<LargePanelLayoutEmpty><InvestmentButtonCreateOpen /></LargePanelLayoutEmpty>)}
            {investments.balance > 0 && (<LargePanelLayoutDetail
                color={color}
                label={"Balance"}
                leftPanel={leftPanel}
                rightPanel={rightPanel}
                subColor={subColor}
                value={`£ ${numberFormat(investments.balance)}`}
            >
                <InvestmentIcon color={color} />
            </LargePanelLayoutDetail>)}
        </LargeGreenPanel>
    )
}
