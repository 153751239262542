import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import TopBanner from './topbanner/TopBanner';
import Dashboard from './dashboard/Dashboard';

import store from './redux/store';

import '@fontsource/roboto/500.css';

import ReactGA from 'react-ga';
const TRACKING_ID='UA-248737825-1';
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <TopBanner />
    <Dashboard />
  </Provider>
);
