import { Card, CardContent, Typography } from "@mui/material"

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const MortgageChartTermBreakdown = ({ data }) => {

    const termData = (data) => {
        const totals = {};
        data.forEach(({ amount, term }) => {
            if (!totals[term]) {
                totals[term] = 0;
            }
            totals[term] += amount; 
        });
        return Object.values(totals);
    };

    const termLabels = (data) => {
        const totals = {};
        data.forEach(({ amount, term }) => {
            if (!totals[term]) {
                totals[term] = 0;
            }
            totals[term] += amount; 
        });
        return Object.keys(totals);
    };

    const options = {
        chart: {
            height: "250px",
            type: "column"
        },
        series: [{
            color: 'blue',
            data: termData(data),
            name: 'Amount per term'
        }],
        title: {
            align: 'center',
            text: 'Mortgage term breakdown'
        },
        xAxis: {
            categories: termLabels(data)
        },
        yAxis: {
            min: 0,
            title: {
              text: 'Loan values (£)'
            }
        }
    };

    return (
        <Card>
            <CardContent>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <Typography variant="p">This represents the loan amounts per term.</Typography>
            </CardContent>
        </Card>
    );
}

export default MortgageChartTermBreakdown;
