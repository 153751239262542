import { Button, Card, CardActions, CardContent, CardHeader, Grid, Paper, Slider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { CurrencyFormatter, formatNumber } from "../util/format";
import { INVESTMENT_ADD } from "./actions/investmentActionTypes";
import InvestmentButtonCreateClose from "./components/InvestmentButtonCreateClose";

const creditScoreLader = [
    { max: 579, min: 500, rate: 4 },
    { max: 619, min: 580, rate: 3 },
    { max: 659, min: 620, rate: 2.5 },
    { max: 699, min: 660, rate: 2 },
    { max: 759, min: 700, rate: 1.5 },
    { max: 850, min: 760, rate: 1 }
]

const ratioLadder = [
    { max: 50, min: 10, term: 120 },
    { max: 75, min: 51, term: 240 },
    { max: 90, min: 76, term: 360 }
];

const FormSlider = ({ change, format, label, max, min, start, step }) => {

    const [value, setValue] = useState(start);

    useEffect(() => {
        setValue(start);
    }, [start]);

    const handleChange = event => {
        change(event.target.value);
        setValue(event.target.value);
    }

    return (
        <>
            <Typography gutterBottom>{ label }</Typography>
            <Slider
                defaultValue={start}
                max={max}
                min={min}
                onChange={handleChange}
                step={step}
                value={value}
                valueLabelDisplay="on"
                valueLabelFormat={format}
            />
        </>
    )
}

const TopPanelCell = ({ label, value }) => (
    <Grid item lg={4} xs={12}>
        <Typography variant="h6" textAlign={"center"}>
            { label } <CurrencyFormatter>{ value }</CurrencyFormatter>
        </Typography>
    </Grid>
)

class InvestmentFormCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            installment: 1.01,
            loan: 100,
            quantity: 2,
            rate: 2,
            ratio: 75,
            score: 660,
            settlement: 30,
            term: 240,
            total: 0
        }
    }

    handleAdd = () => {
        this.props.add(JSON.parse(JSON.stringify(this.state)));
    }

    handleLoansChange = quantity => {
        this.setState({ ...this.state, quantity, installment: this.monthlyInstallment() });
    }

    handleTermChange = term => {
        this.setState({ ...this.state, term, installment: this.monthlyInstallment() });
    }

    handleRateChange = rate => {
        this.setState({ ...this.state, rate, installment: this.monthlyInstallment() });
    }

    handleRatioChange = event => {
        const ratio = Number(event.target.value);
        const term = ratioLadder.find(item => ratio >= item.min && ratio <= item.max).term;
        this.setState({ ...this.state, ratio, term, installment: this.monthlyInstallment() })
    }

    handleScoreChange = event => {
        const score = Number(event.target.value);
        const rate = creditScoreLader.find(item => score >= item.min && score <= item.max).rate;
        this.setState({ ...this.state, rate, score, installment: this.monthlyInstallment() });
    }

    handleSettlementChange = settlement => {
        this.setState({ ...this.state, settlement });
    }

    handleSizeChange = loan => {
        this.setState({ ...this.state, loan, installment: this.monthlyInstallment() });
    }

    monthlyInstallment = () => {
        const amount = this.state.loan * this.state.quantity;
        const monthlyRate = this.state.rate / 12 / 100;
        const factor = Math.pow(1 + monthlyRate, this.state.term);
        const payment = Math.round((amount * factor * monthlyRate) / (factor - 1) * 100) / 100;
        return payment;
    }

    render() {
        return (
            <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card raised sx={{ backgroundColor: '#f2f2fe', borderRadius: '12px', margin: '8px', minHeight: 200 }}>
                        <CardHeader sx={{ textAlign: "center" }} title="Create new investment" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <TopPanelCell label="Total Investment:" value={this.state.loan * this.state.quantity} />
                                <TopPanelCell label="Monthly Return:" value={this.state.installment} />
                                <TopPanelCell label="Total Return:" value={this.state.installment * this.state.term} />
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ color: '#111', justifyContent: 'center' }} >
                            <Button onClick={this.handleAdd}>Add Investment</Button>
                            <InvestmentButtonCreateClose />
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Paper sx={{ backgroundColor: '#fff2f2', borderRadius: '8px', padding: 2}}>
                            <Grid container spacing={2} sx={{ marginTop: 2, minHeight: 300}}>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Typography variant="h6">Risk Factors</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                    <Slider defaultValue={660} max={850} min={500} marks={[{value: 540, label: 'Very Poor'}, {value: 600, label: 'Poor'}, {value: 640, label: 'Fair'}, {value: 665, label: 'Good'}, {value: 730, label: 'Very Good'}, {value: 800, label: 'Excellent'}]} orientation="vertical" sx={{height: 250}} onChange={this.handleScoreChange} />
                                    <Typography>Credit Rating</Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                    <Slider defaultValue={75} max={90} min={10} marks={[{value: 50, label: 'Low'}, {value: 75, label: 'Medium'}, {value: 90, label: 'High'}]} orientation="vertical" sx={{height: 250}} onChange={this.handleRatioChange} />
                                    <Typography>Mortgage to Value Ratio</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Paper sx={{ backgroundColor: '#fffdee', padding: 2 }}>
                            <Stack spacing={2}>
                                <Typography align="center" variant="h6">Loan Settings</Typography>
                                <FormSlider label="Loan Size" format={(x) => `£ ${formatNumber(x)}`} max={5000} min={50} start={this.state.loan} step={50} change={(loan) => this.handleSizeChange(loan)} />
                                <FormSlider label="Loans" format={(x) => x} max={10} min={1} start={this.state.quantity} step={1} change={(quantity) => this.handleLoansChange(quantity)} />
                                <FormSlider label="Interest Rate" format={(x) => `${x} %`} max={5} min={1} start={this.state.rate} step={.01} change={(rate) => this.handleRateChange(rate) } />
                                <FormSlider label="Loan Term (Months)" format={(x) => x} max={360} min={120} start={this.state.term} step={1} change={(term) => this.handleTermChange(term) } />
                                <FormSlider label="Early Settlement Fee" format={(x) => `${x} %`} max={90} min={10} start={this.state.settlement} step={10} change={(settlement) => this.handleSettlementChange(settlement) } />
                            </Stack>
                        </Paper>
                </Grid>
            </Grid>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        add: (data) => dispatch({ type: INVESTMENT_ADD, payload: { data }})
    }
}

export default connect(null, mapDispatchToProps)(InvestmentFormCreate);
