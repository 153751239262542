import { Grid, Paper, Stack, Typography } from "@mui/material"

import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import GroupsIcon from '@mui/icons-material/Groups';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';

const TextPanel = ({ backgroundColor, children, color, text }) => {

    return (
        <Grid item>
            <Paper elevation={3} sx={{
                backgroundColor,
                borderRadius: 3,
                padding: 2,
                margin: '8px'
            }}>
                <Stack direction={"row"} spacing={2}>
                    {children}
                    <Typography variant="subtitle1" sx={{ color, textAlign: 'justify' }}>{text}</Typography>
                </Stack>
            </Paper>
        </Grid>
    )
}

const HelpPanel = () => (
    <TextPanel
        backgroundColor={'#1976d2'}
        color={'#c6e3ff'}
        text={'We need volunteers to form an advisory board, industry experts that will help us with fund raising and launching our platform. When we get our investors on board, we will be able to build our team and start paying salaries.'}
    >
        <EscalatorWarningIcon
            fontSize="large"
            sx={{
                backgroundColor: '#264869',
                borderRadius: '8px',
                color: '#c6e3ff',
                margin: '6px',
                padding: '6px'
            }}
        />
    </TextPanel>
);

const ProblemPanel = () => (
    <TextPanel
        backgroundColor={'#9f1a1a'}
        color={'#e9bebe'}
        text={'It is estimated that in the next year almost 2 million home owners will have to remortgage their homes at a much higher interest rate. At least 20,000 homeowners will maintain their financial dignity with our service.'}
    >
        <AccessAlarmsIcon
            fontSize="large"
            sx={{
                backgroundColor: '#3a0505',
                borderRadius: '8px',
                color: '#e9bebe',
                margin: '6px',
                padding: '6px'
            }}
        />
    </TextPanel>
);

const SimulationPanel = () => (
    <TextPanel
        backgroundColor={'#75788f'}
        color={'#ffffff'}
        text={'By adding a mortgage or investement, or if you dare performing both, you will be able to run a simulation and realise the massive potential in enabling people to better manage their money themselves.'}
    >
        <ContentPasteGoIcon
            fontSize="large"
            sx={{
                backgroundColor: '#203c57',
                borderRadius: '8px',
                color: '#ffffff',
                margin: '6px',
                padding: '6px'
            }}
        />
    </TextPanel>
);

const SolutionPanel = () => (
    <TextPanel
        backgroundColor={'#9f5a1a'}
        color={'#fcc491'}
        text={'Our implementation of peer-to-peer mortgages provide security and higher returns to investors while enabling home owners to borrow money at a rate lower than what they can get at banks.'}
    >
        <GroupsIcon
            fontSize="large"
            sx={{
                backgroundColor: '#3b1c00',
                borderRadius: '8px',
                color: '#fcc491',
                margin: '6px',
                padding: '6px'
            }}
        />
    </TextPanel>
);

const TargetPanel = () => (
    <TextPanel
        backgroundColor={'#fffe42'}
        color={'#66631d'}
        text={'Our objective is to have £280 million worth of mortgages under management within the first year, this will give us a projected monthly income of £700,000.'}
    >
        <ReportGmailerrorredIcon
            fontSize="large"
            sx={{
                backgroundColor: '#cfd35d',
                borderRadius: '8px',
                color: '#66631d',
                margin: '6px',
                padding: '6px'
            }}
        />
    </TextPanel>
);

export const InfoPanel = () => {

    return (
        <Grid container spacing={2}>
            <ProblemPanel />
            <SolutionPanel />
            <TargetPanel />
            <SimulationPanel />
            <HelpPanel />
        </Grid>
    )
}
