import { Grid } from "@mui/material"
import { InvestmentPod } from "../investments/InvestmentPod"

export const InvestmentPanel = ({ investments }) => {

    return (
        <Grid container spacing={2}>
            {investments.pods.map((pod, index) => (
                <Grid item key={pod.id} xs={12}>
                    <InvestmentPod data={pod} title={`Pod ${index + 1}`} />
                </Grid>
            ))}
        </Grid>
    )
}
