import { Card, CardContent, Typography } from "@mui/material";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const MortgageChartPrinciplePayment = ({ data }) => {

    const interestData = (data) => {
        const totals = {};
        data.forEach(loan => {
            loan.detail.forEach(({ interest, payment}) => {
                if (!totals[payment]) {
                    totals[payment] = 0;
                }
                totals[payment] += interest;
            });
        });
        return Object.values(totals);
    }

    const principleData = (data) => {
        const totals = {};
        data.forEach(loan => {
            loan.detail.forEach(({ principle, payment}) => {
                if (!totals[payment]) {
                    totals[payment] = 0;
                }
                totals[payment] += principle;
            });
        });
        return Object.values(totals);
    }

    const labels = (data) => {
        const totals = {};
        data.forEach(loan => {
            loan.detail.forEach(({ principle, payment}) => {
                if (!totals[payment]) {
                    totals[payment] = 0;
                }
                totals[payment] += principle;
            });
        });
        return Object.keys(totals);
    }

    const options = {
        chart: {
            height: "250px",
            type: "column"
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        series: [{
            color: 'red',
            name: 'Interest',
            data: interestData(data)
        }, {
          color: 'blue',
            name: 'Principle',
            data: principleData(data)
        }],
        title: {
           align: 'center',
           text: 'Principle vs Interest payments' 
        },
        xAxis: {
            categories: labels(data)
        },
        yAxis: {
            min: 0,
            title: {
              text: 'Monthly installment (£)'
            }
        }
    }

    return (
        <Card>
            <CardContent>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <Typography variant="p">The dawnward slope indicates the diminishing monthly installments</Typography>
            </CardContent>
        </Card>
    );
}

export default MortgageChartPrinciplePayment;
