
import { Component } from "react";
import { connect } from "react-redux";
import { BlackButton } from "../../components/DefaultButton";
import { MORTGAGE_EARLY_SETTLEMENT } from "../actions/mortgageActionTypes";

class MortgageButtonEarlySettlement extends Component {

    handleCancel = () => {
        this.props.click();
    }

    handleClick = () => {
        this.props.update(this.props.id);
    }

    render() {
        return (
            <>
                <BlackButton click={this.handleClick}>Early Settlement</BlackButton>
                <BlackButton click={this.handleCancel}>Close</BlackButton>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update: (id) => dispatch({ type: MORTGAGE_EARLY_SETTLEMENT, payload: { id }})
    }
}

export default connect(null, mapDispatchToProps)(MortgageButtonEarlySettlement);
