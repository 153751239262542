import { SET_FILTER } from "../actionTypes";
import { VISIBILITY_FILTERS } from '../../constants';

const initialState = VISIBILITY_FILTERS.ALL;

const visibilityFilter = (state = initialState, action) => {

    return action.type === SET_FILTER ? action.payload.filter : state;

}

export default visibilityFilter;
