import { Button } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { MORTGAGE_OFFERS_ACCEPT } from "./mortgageActionTypes";

const color = '#111';

class OffersAcceptButton extends Component {

    handleClick = () => {
        this.props.accept();
    }

    render() {
        return (
            <Button sx={{
                borderColor: color,
                color
            }}
                onClick={this.handleClick}
            >Accept Offers</Button>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        accept: () => dispatch({ type: MORTGAGE_OFFERS_ACCEPT})
    }
}

export default connect(null, mapDispatchToProps)(OffersAcceptButton);
