import { Container, Paper } from "@mui/material"
import { Stack } from "@mui/system";
import { MainHeading } from "./component/MainHeading";
import { MainRow } from "./component/MainRow";

const LargePanel = ({ backgroundColor, children }) => {

    return (
        <Paper elevation={3} sx={{
            backgroundColor: backgroundColor,
            borderRadius: 3,
            height: 160,
            margin: '8px',
            padding: 1
        }}>{children}</Paper>
    )
}

export const LargeGreenPanel = ({ children }) => (<LargePanel backgroundColor={'#329147'} children={children} />);

export const LargePurplePanel = ({ children }) => (<LargePanel backgroundColor={'#5c3d93'} children={children} />);

export const LargePanelLayoutDetail = ({ children, color, label, leftPanel, rightPanel, subColor, value }) => {
    return (
        <Stack spacing={5}>
            <MainHeading color={color} label={label} value={value} >{children}</MainHeading>
            <MainRow color={color} leftPanel={leftPanel} rightPanel={rightPanel} />
        </Stack>
    )
}

export const LargePanelLayoutEmpty = ({ children }) => {
    return (
        <Container sx={{
            marginTop: '50px',
            textAlign: 'center'
        }}>
            { children }
        </Container>
    )
}
