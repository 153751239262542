import React from "react";
import { Grid } from '@mui/material';

import { connect } from "react-redux";
import { MortgageDetails } from "../mortgages/MortgageDetails";
import { Stack } from "@mui/system";
import { MainPanel } from "./MainPanel";
import { InfoPanel } from "./InfoPanel";
import { InvestmentPanel } from "./InvestmentPanel";

const Dashboard = ({ investments, mortgages }) => {
    
    return (
        <>
            <Grid container>
                <Grid item md={8} xs={12}>
                    <Stack>
                        <MainPanel investments={investments} mortgages={mortgages} />
                        {mortgages.loans && mortgages.loans.length > 0 && <MortgageDetails loans={mortgages.loans} />}
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12}>
                    <InvestmentPanel investments={investments} />
                    <InfoPanel />
                </Grid>
            </Grid>
        </>
    )
};

const mapStateToProp = state => {
    const { investments, mortgages } = state;
    return {
        investments, mortgages
    }
}

export default connect(mapStateToProp)(Dashboard);
