import { Button, IconButton } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";

import { INVESTMENT_FORM_OPEN } from '../actions/investmentActionTypes';
import AddIcon from '@mui/icons-material/Add';

class InvestmentButtonCreateOpen extends Component {

    handleClick = () => {
        this.props.toggle();
    }

    render() {
        return this.props.iconOnly ? (
            <IconButton
                size="small"
                variant="outlined"
                sx={{
                    border: '1px solid #fff',
                    borderRadius: '4px',
                    color: '#fff'
                }}
                onClick={this.handleClick}
            ><AddIcon /></IconButton> ) : (
            <Button
                size="large"
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                    borderColor: '#fff',
                    color: '#fff'
                }}
                onClick={this.handleClick}
            >create investment</Button>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggle: () => dispatch({ type: INVESTMENT_FORM_OPEN})
    }
}

export default connect(null, mapDispatchToProps)(InvestmentButtonCreateOpen);
