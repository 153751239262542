import { Card, CardContent, Typography } from "@mui/material"

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const MortgageChartRateBreakdown = ({ data }) => {

    const rateData = (data) => {
        const totals = {}
        data.forEach(({ amount, rate }) => {
            if (!totals[rate]) {
                totals[rate] = 0;
            }
            totals[rate] += amount;
        });
        return Object.values(Object.fromEntries(Object.entries(totals).sort()));
    };

    const rateLabels = (data) => {
        const totals = {}
        data.forEach(({ amount, rate }) => {
            if (!totals[rate]) {
                totals[rate] = 0;
            }
            totals[rate] += amount;
        });
        return Object.keys(Object.fromEntries(Object.entries(totals).sort()));
    };

    const options = {
        chart: {
            height: "250px",
            type: "column"
        },
        series: [{
            color: 'blue',
            data: rateData(data),
            name: 'Amount per interest rate'
        }],
        title: {
            align: 'center',
            text: 'Mortgage rate breakdown'
        },
        xAxis: {
            categories: rateLabels(data)
        },
        yAxis: {
            min: 0,
            title: {
              text: 'Loan values (£)'
            }
        }
    };

    return (
        <Card>
            <CardContent>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <Typography variant="p">This represents the loan amounts per interest rate.</Typography>
            </CardContent>
        </Card>
    )
}

export default MortgageChartRateBreakdown;
