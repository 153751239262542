import { Box } from "@mui/material";

export const formatNumber = number => (!isNaN(number) && number > 0 ? new Intl.NumberFormat().format(Number(number).toFixed(2)) : 0);

export const inputCurrencyFormatter = number => {
    if (isNaN(number)) return '';
    return `£ ${new Intl.NumberFormat().format(Number(number).toFixed(2))}`;
}

export const inputCurrencyRemover = value => {
    return Number(value
        .replaceAll('£', '')
        .replaceAll(',', '')
        .replaceAll(' ', ''));
}

export const CurrencyFormatter = ({ children }) => (
    <Box>£ {formatNumber(children)}</Box>
);
