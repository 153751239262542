import React, { Component } from "react";
import {Button} from '@mui/material';
import { connect } from "react-redux";

import { toggleMortgageForm } from '../actions/action';
import AddIcon from '@mui/icons-material/Add';

class MortgageFormOpenButton extends Component {

    handleOpen = () => {
        this.props.toggleMortgageForm();
    }

    render() {
        return (
            <Button
            size="large"
            startIcon={<AddIcon />}
            variant="outlined"
            sx={{
                borderColor: '#fff',
                color: '#fff'
            }}
            onClick={this.handleOpen}
        >Add Mortgage</Button>
        )
    }

}

export default connect(null, { toggleMortgageForm })(MortgageFormOpenButton);
