import { LargePanelLayoutDetail, LargePanelLayoutEmpty, LargePurplePanel } from "../dashboard/LargePanel"
import MortgageFormOpenButton from "./components/MortgageFormOpenButton"

import HomeIcon from '@mui/icons-material/Home';
import { numberFormat } from "highcharts";
import { formatNumber } from "../util/format";

const MortgageIcon = ({ color }) => (
    <HomeIcon
        fontSize="large"
        sx={{
            backgroundColor: '#352354',
            borderRadius: '8px',
            color,
            margin: '6px',
            padding: '6px'
        }}
    />
);

export const MortgageBalancePanel = ({ mortgages }) => {
    const color = '#f0e7ff';
    const subColor = '#938ba1';

    const maxTerm = () => {
        return mortgages.loans.length ? mortgages.loans.sort((a, b) => b.term - a.term)[0].term : 0;
    }

    const leftPanel = {
        label: 'Installment',
        value: mortgages.loans ? `£ ${formatNumber(mortgages.loans.reduce((total, loan) => total + loan.installment, 0))}` : 0
    }

    const rightPanel = {
        label: 'Term',
        value: `${formatNumber(maxTerm())} months`
    }

    return (
        <LargePurplePanel>
            {mortgages.balance === 0 && <LargePanelLayoutEmpty><MortgageFormOpenButton /></LargePanelLayoutEmpty>}
            {mortgages.balance > 0 && <LargePanelLayoutDetail
                color={color}
                label={"Balance"}
                leftPanel={leftPanel}
                rightPanel={rightPanel}
                subColor={subColor}
                value={`£ ${numberFormat(mortgages.balance)}`}
            >
                <MortgageIcon color={color} />
            </LargePanelLayoutDetail>}
        </LargePurplePanel>
    )
}
