import { Stack, Typography } from "@mui/material"

const Heading = ({ color, label, value }) => (
    <Stack sx={{
        paddingRight: '6px',
        textAlign: "right",
        width: '100%'
    }}>
        <Typography variant="h4" sx={{ color }}>{value}</Typography>
        <Typography variant="caption" sx={{ color }}>{label}</Typography>
    </Stack>
);

export const MainHeading = ({ children, color, label, value }) => {

    return (
        <Stack direction={"row"}>
            <div>{children}</div>
            <Heading color={color} label={label} value={value} />
        </Stack>
    )
}
