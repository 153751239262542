import { Button } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { INVESTMENT_FORM_CLOSE } from "../actions/investmentActionTypes";

class InvestmentButtonCreateClose extends Component {

    handleClick = () => {
        this.props.toggle();
    }

    render() {
        return (
            <Button onClick={this.handleClick}>Close</Button>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggle: () => dispatch({ type: INVESTMENT_FORM_CLOSE })
    }
}

export default connect(null, mapDispatchToProps)(InvestmentButtonCreateClose);
