import { ADD, CANCEL, TOGGLE } from "../../redux/actionTypes";
import { TOP_BANNER_LEAP } from "../../topbanner/actions/TopBannerActionTypes";

import { MORTGAGE_EARLY_SETTLEMENT, MORTGAGE_FORM_INPUT_CANCEL, MORTGAGE_OFFERS, MORTGAGE_OFFERS_ACCEPT, MORTGAGE_OFFERS_UPDATE, MORTGAGE_OFFER_SELECT_TOGGLE, MORTGAGE_UPDATE_AMOUNT } from '../actions/mortgageActionTypes';

const initialState = {
    balance: 0,
    displayForm: false,
    quote: {
        amount: 0,
        installment: 0,
        offers: [],
        rateAverage: 0,
        selectedOffers: 0,
        sorting: {
            installment: '',
            offer: '',
            rate: '',
            term: ''
        },
        term: 0,
        totalOffers: 0
    },
    loans: []
};

const leapMortgage = state => {
    state.loans.forEach(loan => {
        loan.detail = loan.detail.filter(item => item.payment > 1);
        loan.detail.forEach(((item, index) => item.payment = index + 1));
        loan.amount = loan.detail[0].balance;
        loan.installment = loan.detail[0].interest + loan.detail[0].principle;
        loan.term = loan.detail.length;
        loan.totalInterest = loan.detail.reduce((total, item) => total + item.interest, 0);
    });
    state.balance = state.loans.reduce((total, item) => total + item.amount, 0);
    return {
        ...state
    }
}

export const mortgages = (state = initialState, action) => {
    switch (action.type) {
        case ADD: {
            const { content } = action.payload;
            return {
                balance: content,
                displayForm: false
            }
        }
        case CANCEL: {
            return {
                ...state,
                displayForm: false
            }
        }
        case TOGGLE: {
            return {
                ...state,
                displayForm: true
            }
        }
        case MORTGAGE_EARLY_SETTLEMENT: {
            const { id } = action.payload;
            const currentLoans = JSON.parse(JSON.stringify(state.loans));
            const loans = currentLoans.filter(loan => loan.id !== id);
            const balance = loans.reduce((total, loan) => total + loan.amount, 0);
            return {
                ...state,
                balance, loans
            }
        }
        case MORTGAGE_FORM_INPUT_CANCEL: {
            return {
                ...state,
                displayForm: false
            }
        }
        case MORTGAGE_OFFER_SELECT_TOGGLE: {
            const { id } = action.payload;
            const offers = state.quote.offers;
            const selected = offers.find(offer => offer.id === id).selected;
            offers.find(offer => offer.id === id).selected = !selected;
            return {
                ...state,
                quote: {
                    offers
                }
            }
        }
        case MORTGAGE_OFFERS: {
            const { amount, offers, totalOffer } = action.payload;
            return {
                ...state,
                quote: {
                    amount, offers, totalOffer
                }
            }
        }
        case MORTGAGE_OFFERS_ACCEPT: {
            const loans = state.quote.offers.filter(item => item.selected);
            const balance = loans.reduce((total, loan) => total + loan.amount, 0);
            return {
                balance,
                displayForm: false,
                loans,
                quote: {}
            }
        }
        case MORTGAGE_OFFERS_UPDATE: {
            const { offers } = action.payload;
            const quote = state.quote;
            const selected = offers.filter(offer => offer.selected);
            const selectedOffers = selected.reduce((total, offer) => total + offer.amount, 0);
            const installment = selected.reduce((total, offer) => total + offer.installment, 0);
            const term = selected.length > 0 ? selected.sort((a, b) => b.term - a.term)[0].term : 0;
            const rateAverage = (selected.reduce((total, offer) => total + offer.rate, 0)) / selected.length;
            return {
                ...state,
                quote: {
                    ...quote,
                    installment,
                    offers: offers,
                    rateAverage,
                    selectedOffers,
                    term
                }
            }
        }
        case MORTGAGE_UPDATE_AMOUNT: {
            const { amount } = action.payload;
            return {
                ...state,
                quote: {
                    amount
                }
            };
        }
        case TOP_BANNER_LEAP: {
            return {
                ...leapMortgage(state)
            }
        }
        default:
            return state;
    }
}