import { Button } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { INVESTMENT_TOPUP } from "../actions/investmentActionTypes";

class InvestmentButtonTopup extends Component {

    render() {
        return (
            <Button variant="text" sx={{ color: '#fff' }} onClick={() => this.props.topup(this.props.id)}>Topup Cash (£ {Number(this.props.cash).toFixed(2)})</Button>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        topup: (id) => dispatch({ type: INVESTMENT_TOPUP, payload: { id }})
    }
}

export default connect(null, mapDispatchToProps)(InvestmentButtonTopup);
